





















































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { shareStoreActions } from '@/store/share.store';
import ShareConfiguration from '@/models/ShareConfiguration';
import { validationMixin } from 'vuelidate';
import { decimal, maxValue, minValue, numeric, required } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';

const ShareStore = namespace('share');

@Component({
    mixins: [validationMixin],
    validations: {
        share: { required, minValue: minValue(1), maxValue: maxValue(1000), decimal }
    }
})
export default class ShareManagementView extends mixins(ErrorMessageHandlerMixin) {
    @ShareStore.Action(shareStoreActions.GET_CONFIGURATION)
    private getConfiguration!: () => Promise<ShareConfiguration>;

    @ShareStore.Action(shareStoreActions.SET_PRICE_PER_RIDE)
    private setShare!: (price: number) => Promise<ShareConfiguration>;

    @ShareStore.Action(shareStoreActions.SET_PRICE_PER_RIDE_ACTIVE)
    private setShareActive!: (active: boolean) => Promise<ShareConfiguration>;

    /**
     * flag that indicates whether the view is loading
     * @private
     */
    private isLoading: boolean = false;

    /**
     * flag that indicates that an error occurred while loading the share configuration
     * @private
     */
    private errorOnLoading: boolean = false;

    /**
     * flag used to show loading flag inside the confirm dialog of current share
     * @private
     */
    private isLoadingShareDialog: boolean = false;

    /**
     * flag used to show loading flag inside the confirm dialog of share active
     * @private
     */
    private isLoadingShareActiveDialog: boolean = false;

    /**
     * v-model to control the isShareActive state
     * @private
     */
    private isShareActive: boolean = true;

    /**
     * v-model to control the current share
     * @private
     */
    private share: string = '';

    /**
     * flag to control the confirm dialog opening for share active changes
     * @private
     */
    private showShareActiveConfirmDialog: boolean = false;

    /**
     * flag to control the confirm dialog opening for current share changes
     * @private
     */
    private showCurrentShareConfirmDialog: boolean = false;

    /**
     * configuration loaded from the api
     * @private
     */
    private configuration = new ShareConfiguration();

    private async created() {
        try {
            this.isLoading = true;
            this.configuration = await this.getConfiguration();
            this.isShareActive = this.configuration.pricePerRideActive;
            this.share = this.configuration.pricePerRide.toString();
        } catch (e) {
            this.$notifyErrorSimplified('SHARE.ERROR');
            this.errorOnLoading = true;
        } finally {
            this.isLoading = false;
        }
    }

    private onCloseDialog() {
        this.showShareActiveConfirmDialog = false;
        this.showCurrentShareConfirmDialog = false;
    }

    private async onSaveShareActive() {
        this.showShareActiveConfirmDialog = true;
    }

    private async onSaveShareActiveConfirmed() {
        try {
            this.isLoadingShareActiveDialog = true;
            this.configuration = await this.setShareActive(this.isShareActive);
            this.showShareActiveConfirmDialog = false;
            this.$notifySuccessSimplified('SHARE.SHARE_ACTIVE.SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('SHARE.SHARE_ACTIVE.ERROR');
        } finally {
            this.isLoadingShareActiveDialog = false;
        }
    }

    private onSaveCurrentShare() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.showCurrentShareConfirmDialog = true;
    }

    private async onSaveCurrentShareConfirmed() {
        try {
            this.isLoadingShareDialog = true;
            const pricePerRide = parseFloat(this.share);
            this.configuration = await this.setShare(pricePerRide);
            this.showCurrentShareConfirmDialog = false;
            this.$notifySuccessSimplified('SHARE.CURRENT_SHARE.SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('SHARE.CURRENT_SHARE.ERROR');
        } finally {
            this.isLoadingShareDialog = false;
        }
    }

    private get hasCurrentShareChanged() {
        return this.configuration?.pricePerRide?.toString() !== this.share;
    }
}
